const Main = ({ children }) => (
  <main
    className="flex flex-col items-center justify-start flex-grow w-full h-full"
    style={{ height: 'max-content', maxWidth: '1200px', paddingLeft: '20px', paddingRight: '20px' }}
  >
    {children}
  </main>
)

export default Main
