import { ChainId } from '@sushiswap/sdk'

export const CCD_ADDRESS = {
  [ChainId.MAINNET]: '0xF71a2cbb84fA4DECdc403a13866E2976168Efbd2',
  [ChainId.RINKEBY]: '0x21b16e9758e9265f66188c122A2A44912d9C9347',
}

export const GEM_ADDRESS = {
  [ChainId.MAINNET]: '0xB666FC877A71eBa7B2fC5df848eFEa377b2Fbb03',
  [ChainId.RINKEBY]: '0x9821bc0F67DA1bce1Ef8b0D39aA282dE1fFE5475',
}

export const BABY_ADDRESS = {
  [ChainId.MAINNET]: '0x11Fe30D8a4268f46f87946C2b10907fe16c4C3D7',
  [ChainId.RINKEBY]: '0x5Bb41B16764b92A9114e9279f1F37Af1e1429FF4',
}

export const MARKET_ADDRESS = {
  [ChainId.MAINNET]: '0x756DB70d529942306cF50aC05Bd3785aa8110993',
  [ChainId.RINKEBY]: '0x26C5f1034b2ca7B5214abd6305989e0B7A7F7f39',
}

export const STAKING_ADDRESS = {
  [ChainId.MAINNET]: '0x807Fb10543B5432744600f6c88B8383DDb4A96BB',
  [ChainId.RINKEBY]: '0xa278f7F33a0165b683082FF9B3f610Ae08A106a8',
}
