import { CurrencyAmount, Token } from '@sushiswap/sdk'

import { useMemo } from 'react'
import { BABY_ADDRESS, CCD_ADDRESS, GEM_ADDRESS, STAKING_ADDRESS } from '../config/addresses'
import { useSingleCallResult } from '../state/multicall/hooks'
import { useActiveWeb3React } from './useActiveWeb3React'
import { useBabyContract, useCCDContract, useTokenContract } from './useContract'

export function useTokenAllowance(token?: Token, owner?: string, spender?: string): CurrencyAmount<Token> | undefined {
  const contract = useTokenContract(token?.address, false)

  const inputs = useMemo(() => [owner, spender], [owner, spender])
  const allowance = useSingleCallResult(contract, 'allowance', inputs).result

  return useMemo(
    () => (token && allowance ? CurrencyAmount.fromRawAmount(token, allowance.toString()) : undefined),
    [token, allowance]
  )
}

export function useNFTAllowance() {
  const { account, chainId } = useActiveWeb3React()
  const contract = useBabyContract(BABY_ADDRESS[chainId])
  const args = useMemo(() => {
    if (!account) {
      return
    }
    return [String(account), STAKING_ADDRESS[chainId]]
  }, [account])
  
  const staked = useSingleCallResult(contract, 'isApprovedForAll', args)?.result
  return staked?.[0] !== undefined ? staked?.[0] : false
  // console.log(staked?.[0]) 
}